<template>
  <div>
    <button class="profile-image-edit-btn" v-b-modal.modal-edit-profile-pic>
      <i class="ri-pencil-line"></i>
    </button>

    <b-modal id="modal-edit-profile-pic" title="Profile Picture" hide-footer>
      <img
        style="width: 100%; max-width: 240px; display: block; margin: auto"
        :src="cover_image"
        alt="Profile Pic"
        srcset=""
      />

      <FormulateInput
        type="image"
        name="image"
        v-model="image"
        label="Select an image to upload"
        help="Select a png, jpg or gif to upload."
        validation="mime:image/jpeg,image/png,image/gif"
      />
      <div class="d-flex justify-content-between">
        <b-button @click="removePicture" variant="danger" size="sm">
          <span v-if="removing">Removing...</span>
          <span v-else> Remove </span>
        </b-button>
        <b-button @click="uploadPicture" variant="primary" size="sm">
          <span v-if="uploading">Changing...</span> <span v-else> Change </span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { users } from "@/config/api/users.js";
import Swal from "sweetalert2";
import { getFormData } from "@/utils/createForm";
export default {
  props: ["cover_image"],
  data() {
    return {
      image: null,
      uploading: false,
      removing: false,
    };
  },
  methods: {
    uploadPicture() {
      if (this.image && this.image.files && this.image.files[0]) {
        this.uploading = true;
        const api = users.changeProfilePic;
        api.data = getFormData({ file: this.image.files[0].file });
        api.id = this.$route.query.id;
        this.generateMultipartAPI(api)
          .then((res) => {
            Swal.fire("", "Uploaded successfully!", "success");
            this.$bvModal.hide("modal-edit-profile-pic");
            this.$emit("updated", res.data.user.cover_image);
          })
          .catch((err) => {
            Swal.fire(
              "Failed",
              `Failed with error ${err.response.message}`,
              "error"
            );
          })
          .finally(() => {
            this.uploading = false;
            this.removing = false;
          });
      } else {
        Swal.fire("", "Please choose profile pic", "warning");
      }
    },
    removePicture() {
      this.removing = true;
      const api = users.deleteProfilePic;
      api.id = this.$route.query.id;
      this.generateAPI(api)
        .then(() => {
          Swal.fire("", "Profile pic is deleted!", "success");
          this.$bvModal.hide("modal-edit-profile-pic");
          this.$emit("updated", null);
        })
        .catch((err) => {
          Swal.fire(
            "Failed",
            `Failed with error ${err.response.message}`,
            "error"
          );
        })
        .finally(() => {
          this.removing = false;
        });
    },
  },
};
</script>

<style>
#modal-edit-profile-pic .modal-dialog {
  max-width: 380px !important;
  margin: auto;
  margin-top: 28px;
}
.profile-image-edit-btn {
  background-color: #fff;
  width: 28px;
  height: 28px;
  outline: none;
  border: 1px solid white;
  display: none;
  position: absolute;
  top: 0px;
  right: 8px;
  border-radius: 50%;
}
#profile-img:hover .profile-image-edit-btn {
  display: block;
  border: 1px solid #576e85;
  transition: display 2s;
}
</style>
