<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import Swal from "sweetalert2";
import { users } from "@/config/api/users";
import { awards } from "@/config/api/awards";
import UserModal from "@/components/users/user-modal";
import EditPasswordModal from "@/components/users/edit-password";
import MembershipModal from "@/components/users/add-company-membership";
import { companies } from "@/config/api/companies";
import editProfilepic from "@/components/users/edit-profilepic";

/**
 * Product Detail Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    UserModal,
    EditPasswordModal,
    MembershipModal,
    editProfilepic,
  },
  data() {
    return {
      userId: null,
      allCompanies: [],
      userInfo: [],
      companiesData: [],
      membershipData: [],
      userAwards: [],
      title: "User Info",
      selectedUser: null,
      loading: false,
      items: [
        {
          text: "Users",
          to: { path: `/users` },
        },
        {
          text: "User Info",
          active: true,
        },
      ],
    };
  },
  created() {
    if (this.$route.query.id) {
      this.userId = this.$route.query.id;
      this.loadInfo();
    } else {
      throw "user_id is missing!";
    }
  },
  async beforeMount() {
    await this.getAllCompanies();
    await this.getUserAwards();
  },
  computed: {},
  methods: {
    async getAllCompanies() {
      if (this.userId) {
        const api = users.getCompanies;
        api.id = this.userId;
        try {
          let res = await this.generateAPI(api);
          this.companiesData = res.data.models;
        } catch (error) {
          console.error(error);
        }
      } else {
        throw "user_id is missing!";
      }
    },
    async loadInfo() {
      if (this.userId) {
        const api = users.getById;
        api.id = this.userId;
        this.loading = true;
        try {
          let res = await this.generateAPI(api);
          this.userInfo = res.data.user;

        } catch (error) {
          console.error(error);
        }
      } else {
        throw "user_id is missing!";
      }
      this.loading = false;
    },
    showUserActionModal() {
      this.$bvModal.show("action-User");
    },
    showUserViewModal() {
      this.$bvModal.show("action-UserView");
    },
    showActionModal() {
      this.$bvModal.show("action-User");
    },
    showMemberModal() {
      let api = companies.get;
      this.generateAPI(api).then((res) => {
        this.allCompanies = res.data.companies;
      });
      this.selectedUser = this.userInfo;

      this.$bvModal.show("action-Membership");
    },
    hideActionModal() {
      this.$bvModal.hide("action-User");
    },
    showEditPasswordModal(data) {
      this.selectedUser = data;
      this.$bvModal.show("action-Password");
    },
    hideEditPasswordModal() {
      this.$bvModal.hide("action-Password");
    },
    editUser(data) {
      this.selectedUser = data;
      this.showActionModal();
    },
    deleteUser(data) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: "Are you sure, you want to delete this user?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.value) {
            this.loading = true;
            const api = users.delete;
            api.id = data._id;
            this.generateAPI(api)
              .then(() => {
                this.$bvToast.toast("User deleted successfully!", {
                  title: "Delete",
                  variant: "success",
                  solid: true,
                });
              })
              .catch((err) => {
                this.$bvToast.toast("User is not deleted", {
                  title: "Delete",
                  variant: "danger",
                  solid: true,
                });
                console.error(err);
                this.loadInfo();
              })
              .finally(() => {
                this.loading = false;
              });
          }
        });
    },

    showMemberView(val) {
      this.$router.push({
        path: "companies/member-info",
        query: {
          cid: val.company_id._id,
          mid: val._id,
        },
      });
    },
    async getUserAwards() {
      if (this.userId) {
        this.loading = true;
        const api = awards.users.getByUserId;
        api.id = this.userId;
        try {
          let res = await this.generateAPI(api);
          this.userAwards = res.data.models;
        } catch (error) {
          console.error(error);
        }
      } else {
        throw "user_id is missing!";
      }
      this.loading = false;
    },
  },
  watch: {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div v-if="loading">
      <div style="text-align: center" class="mt-5 mb-5">
        <pulse-loader color="#505d69" :loading="loading"></pulse-loader>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div style="text-align: end; float-right" class="mb-2 mb-2">
              <b-button class="mr-1" type="button" @click="deleteUser(userInfo)" pill variant="danger">
                Delete
              </b-button>

              <b-button style="cursor: pointer" @click="editUser(userInfo)" pill variant="primary">
                Edit
              </b-button>
              <b-button style="cursor: pointer" @click="showEditPasswordModal(userInfo)" pill variant="outline-primary">
                Change Password
              </b-button>
            </div>

            <div class="row">
              <div class="col-xl-6">
                <div class="mt-4 mt-xl-3">
                  <a v-if="userInfo.name" href="#" class="text-primary">{{
                  userInfo.name
                  }}</a>
                  <h5 class="mt-1 mb-3">{{ userInfo.name }}</h5>
                  <h5 class="mt-1 mb-3">{{ userInfo.email }}</h5>

                  <hr class="my-4" />
                  <div class="row">
                    <div class="col-md-8">
                      <h5 class="font-size-14">Basic Info :</h5>
                      <ul class="list-unstyled product-desc-list">
                        <li>
                          Full Name: {{ userInfo.first_name }}
                          {{ userInfo.name }}
                        </li>
                        <li>
                          Account Status:
                          {{
                          userInfo.deletedAt ? "Inactive (Deleted)" : "Active"
                          }}
                        </li>
                        <li v-if="userInfo.is_email_verified" class="verified_icon">
                          Email Verified:
                          <i style="color: green; font-size: 1.2rem" class="ri-checkbox-circle-fill"></i>
                        </li>
                        <li v-if="!userInfo.is_email_verified" class="verified_icon">
                          Verified:
                          <i class="ri-close-circle-line verified_icon" style="color: red; font-size: 1.2rem"></i>
                        </li>
                        <li>Expert: {{ userInfo.is_expert ? "Yes" : "No" }}</li>
                        <li>
                          Private: {{ userInfo.is_private ? "Yes" : "No" }}
                        </li>
                        <li>Profession: {{ userInfo.profession }}</li>
                        <li v-if="userInfo.description">
                          Description: {{ userInfo.description }}
                        </li>
                        <li v-if="userInfo.phone_number">
                          Phone number:
                          {{
                          `(${userInfo.country_code})(${userInfo.phone_number})`
                          }}
                        </li>
                        <li v-if="userInfo.linkedin_url">
                          Linked In:
                          <a :href="userInfo.linkedin_url" target="_blank"> {{userInfo.linkedin_url}}</a>

                        </li>
                        <li v-if="userInfo.instagram_url">
                          Instagram:
                          <a :href="userInfo.instagram_url" target="_blank"> {{userInfo.instagram_url}}</a>

                        </li>
                        <li v-if="userInfo.facebook_url">
                          Facebook:
                          <a :href="userInfo.facebook_url" target="_blank"> {{userInfo.facebook_url}}</a>

                        </li>

                        <li v-if="userInfo.nationality">
                          Nationality:
                          {{userInfo.nationality.name}}
                        </li>
                        <li v-if="userInfo.plan_level">
                          Plan Level:
                          {{userInfo.plan_level.name}}
                        </li>
                      </ul>
                    </div>
                    <div class="d-flex flex-md-column align-items-center">
                      <h5 class="font-size-14">Awards :</h5>
                      <b-container fluid class="p-1">
                        <b-row>
                          <b-col v-for="item in userAwards" :key="item._v">
                            <router-link :to="{
                              path: `/awards-users-info`,
                              query: { id: item._id },
                            }">
                              <b-img width="100" thumbnail fluid :src="require('@/assets/award.png')" alt="Image 1">
                              </b-img>
                              <div style="text-align: center">
                                <span>{{ item.name }}</span>
                              </div>
                            </router-link>
                          </b-col>
                        </b-row>
                      </b-container>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="product-detail">
                  <div class="row">
                    <div class="col-md-11 col-9">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div class="tab-pane fade show active" id="product-1" role="tabpanel">
                          <div class="col-md-6">
                            <div class="mt-4 mt-md-0">
                              <div id="profile-img">
                                <edit-profilepic :cover_image="userInfo.cover_image" @updated="
                                  (new_pic) =>
                                    (userInfo.cover_image = new_pic)
                                " />
                                <img v-if="userInfo.cover_image" class="rounded-circle avatar-xl" alt="Profile Pic"
                                  style="border: 2px solid #576e85" :src="userInfo.cover_image"
                                  data-holder-rendered="true" />
                                <img v-else class="rounded-circle avatar-xl" alt="Profile Pic"
                                  style="border: 2px solid #576e85" src="@/assets/mawj/logo.svg"
                                  data-holder-rendered="true" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
            </div>
            <!-- end row -->

            <div class="mt-4">
              <h5 class="font-size-14 mb-3">More Info:</h5>
              <div style="text-align: end; float-right">
                <b-button @click="showMemberModal" pill variant="primary">
                  Add Membership
                </b-button>
              </div>
              <div class="product-desc">
                <b-tabs class="nav-tabs-custom" content-class="border border-top-0 p-4">
                  <b-tab title="Memberships">
                    <div class="col-md-6">
                      <div class="product-color mt-3">
                        <a v-for="member in companiesData" :key="member._id" @click="showMemberView(member)"
                          style="cursor: pointer">
                          <div class="product-color-item">
                            <img :src="member.company_id.cover_image" alt="pic" class="avatar-md" />
                          </div>
                          <p>{{ member.company_id.display_name }}</p>
                        </a>
                      </div>
                    </div>
                    <div class="col-md-6" v-if="companiesData.length === 0">
                      No Members
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
    <!-- end card -->
    <!-- end row -->
    <UserModal :currentCompany="companiesData" :selectedUser="selectedUser" v-on:reloadData="loadInfo"
      v-on:resetModal="selectedUser = null" v-on:closeModal="hideActionModal" />
    <EditPasswordModal :selectedUser="selectedUser" v-on:reloadData="loadInfo" v-on:resetModal="selectedUser = null"
      v-on:closeModal="hideEditPasswordModal" />
    <MembershipModal :allCompanies="allCompanies" :currentCompany="companiesData" :selectedUser="selectedUser"
      v-on:resetModal="selectedUser = null" v-on:reloadData="loadInfo" v-on:closeModal="hideActionModal" />
  </Layout>
</template>

<style scoped>
.verified_icon {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

#profile-img {
  position: relative;
  width: fit-content;
}
</style>
