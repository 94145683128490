<script>
import { users } from "@/config/api/users";
export default {
    props: ["selectedUser"],
    data() {
        return {
            values: {},
            performAction: false,
            schema: [
                {
                    index: 0,
                    type: "text",
                    name: "password",
                    label: "Enter new Password",
                    validation: "required",
                },
            ],
        };
    },
    methods: {
        async submitHandler(data) {
            console.log(this.selectedUser);
            this.updatePassword(data);
        },
        updatePassword(data) {
            this.performAction = true;
            const api = users.editPassword;
            api.data = { user_id: this.selectedUser._id, new_password: data.password };
            console.log("dsihidfsh")
            console.log(api.data);
            this.generateAPI(api)
                .then((res) => {
                    console.log(res.data);
                    this.$bvToast.toast("Password updated successfully!", {
                        title: "Update",
                        variant: "success",
                        solid: true,
                    });
                    this.$emit("reloadData");
                })
                .catch((err) => {
                    this.$bvToast.toast("Password is not updated!", {
                        title: "Update",
                        variant: "danger",
                        solid: true,
                    });
                    console.error(err.response.data);
                })
                .finally(() => {
                    this.performAction = false;
                    this.$emit("closeModal");
                });
        },
    },
    watch: {
        selectedPassword(val) {
            console.log("selected Password");
            if (val) {
                this.values = { ...val };
            }
        },
    },
};
</script>

<template>
    <b-modal id="action-Password" @hidden="$emit('resetModal')" :title="'Change User Password'" hide-footer>
        <FormulateForm name="PasswordAction" v-model="values" :schema="schema" @submit="submitHandler">
            <FormulateInput type="submit" class="float-right">
                <span v-if="performAction">
                    <b-spinner class="vueformulate-loader"></b-spinner>
                    <span> Updating... </span>
                </span>
                <span v-else>
                    <span> Update </span>
                </span>
            </FormulateInput>
        </FormulateForm>
    </b-modal>
</template>

<style>
.payment-duration .formulate-input-group {
    display: flex;
}

.payment-duration .formulate-input-group-item {
    margin: 8px;
}

.payment-duration .formulate-input-label {
    margin-top: -6px;
}
</style>
