<script>
import { companies } from "@/config/api/companies";

// import { getFormData } from "@/utils/createForm";

/**
 * Add User Component
 */
export default {
  props: ["currentCompany", "selectedUser", "allCompanies"],
  data() {
    return {
      title: "Users",
      items: [
        {
          text: "All Users",
          to: { path: `/users` },
        },
        {
          text: "Add",
          active: true,
        },
      ],
      userId: null,
      loading: false,
      values: {},
      
    };
  },
  methods: {
    async submitHandler(data) {
        this.addUser(data);

    },
    addUser(data) {
      this.loading = true;
      const api = companies.addMember;
      api.data = {
        ...data,
        isAdmin: data.isAdmin ? data.isAdmin : false,
        user_id: this.selectedUser._id,
      };
      console.log(api);
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("Company membership added successfully!", {
            title: "Create",
            variant: "success",
            solid: true,
          });
          this.$emit("reloadData");
          this.$emit("closeModal");
        })
        .catch((err) => {
          this.$bvToast.toast("Company membership not added!", {
            title: "Create",
            variant: "danger",
            solid: true,
          });
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
          this.$emit("closeModal");
        });
    },
  },
  watch: {
    selectedUser(val) {
        console.log(val);
      if (val) {
        this.values = { ...val };
        
        

      }
    },
  },
  computed: {
      selectCompany(){
          console.log(this.allCompanies)
          return this.allCompanies? this.allCompanies.map(item=>{
        return{
          label:item.display_name,
          value:item._id
        }
      }):[]
      },
      schema(){ 
          if(this.selectedUser)
          {
              return [
        {
          index: 1,
          type: "text",
          name: "name",
          label: "User name",
          placeholder: "John Doe",
          "validation-messages": { required: "User Name is required" },
          validation: "required",
          value: this.selectedUser.name,
          disabled: true,
        },
        {
          index: 2,
          type: "text",
          name: "email",
          label: "User email",
          "validation-messages": { required: "User Email is required" },
          placeholder: "example@email.com",
          validation: "required|email",
          value: this.selectedUser.email,
          disabled: true,
        },
        {
          index: 3,
          type: "text",
          name: "position",
          label: "Position",
        },
        {
          index: 4,
          type: "checkbox",
          name: "isAdmin",
          label: "Admin",
        },
        
      ]
          }
          else
          return []
      },
  }
};
</script>

<template>
  <b-modal
    id="action-Membership"
    size="xl"
    @hidden="$emit('resetModal')"
    title="Add Membership"
    hide-footer
  >
    <FormulateForm
      name="UserAction"
      v-model="values"
      :schema="schema"
      @submit="submitHandler"
    >

    <FormulateInput
          type="select"
          name="company_id"
          label="Company"
          placeholder="Please select Company"
          validation="required"
          :options="selectCompany"
        />
      <FormulateInput type="submit" class="float-right">
        <span v-if="loading">
          <b-spinner class="vueformulate-loader"></b-spinner>
          <span v-if="selectedUser"> Adding... </span>
        </span>
        <span v-else>
          <span v-if="selectedUser"> Add </span>
        </span>
      </FormulateInput>
    </FormulateForm>
  </b-modal>
</template>


<style>
.payment-duration .formulate-input-group {
  display: flex;
}

.payment-duration .formulate-input-group-item {
  margin: 8px;
}
.payment-duration .formulate-input-label {
  margin-top: -6px;
}

@media (min-width: 1200px) {
  .modal-dialog {
    max-width: 1650px !important;
  }
}
@media (min-width: 992px) {
  .modal-xl {
    max-width: 1200px !important;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 950px !important;
  }
}
</style>